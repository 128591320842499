<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              My Profile
            </h1>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-md-right d-md-block d-none mb-1"
        md="3"
        cols="12"
      >
        <b-button
          variant="primary"
          class="mr-1"
          :to="{ name: 'directory-my-profile-change-password' }"
        >
          <feather-icon
            icon="LockIcon"
            class="mr-50"
            size="16"
          />
          <span class="align-middle">Change Password</span>
        </b-button>
        <b-button
          variant="primary"
          :to="{ name: 'directory-my-profile-update' }"
        >
          <feather-icon
            icon="EditIcon"
            class="mr-50"
            size="16"
          />
          <span class="align-middle">Edit</span>
        </b-button>
      </b-col>
    </b-row>
    <!-- Table Container Card -->

    <b-card
      class="mb-3 p-2 user__profile"
    >
      <b-table-simple
        borderless
        responsive
      >
        <b-tbody>
          <b-tr>
            <b-th
              class="image__block"
            >
              <b-img
                class="mb-1 mb-sm-0 user__avatar"
                height="100"
                :src="user.image || defaultImage"
              />
            </b-th>
          </b-tr>
          <b-tr>
            <b-th>
              Name
            </b-th>
            <b-td>{{ user.name }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Email
            </b-th>
            <b-td>{{ user.email }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Position
            </b-th>
            <b-td>{{ user.position }}</b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Department
            </b-th>
            <b-td>
              <div class="">
                <p
                  v-for="(department, key) in user.department"
                  :key="key"
                  class="mb-0"
                >
                  {{ department.name }}
                </p>
              </div>
            </b-td>
          </b-tr>
          <b-tr>
            <b-th>
              Roles
            </b-th>
            <b-td>
              <!-- <strong
                v-for="(role, key) in user.roles"
                :key="key"
              >
                {{ role.role }}<span v-if="key + 1 != user.roles.length">, </span>
              </strong> -->
              <b-button
                v-for="(role, key) in user.roles"
                :key="key"
                :title="role.role"
                variant="outline-danger"
                class="small-spacing-button"
              >
                {{ role.role }}
              </b-button>
            </b-td>
          </b-tr>
          <b-tr
            class="last__row"
          >
            <b-th>
              Status
            </b-th>
            <b-td
              class="text-capitalize"
            >
              <span :class="resolveStatusClass(user.status)">{{ user.status }}</span>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BButton, BCol, BTableSimple, BTbody, BTr, BTh, BTd, BImg,
} from 'bootstrap-vue'
import store from '@/store/index'

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BImg,
  },
  data() {
    return {
      user: store.state.auth.userData,
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/frontend/logo-nirvana-memorial-garden.86fe2ff5.svg'),
    }
  },
  created() {
    console.log(this.user)
  },
  methods: {
    resolveStatusClass(status) {
      if (status === 'active') return 'text-success'
      if (status === 'inactive') return 'text-danger'
      return 'text-primary'
    },
  },
}
</script>
